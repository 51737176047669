import { icons, markers, INDEX_URL } from "../helpers/constants";

export const checkToken = () => {
	if (localStorage.getItem("accesstoken") == "" || localStorage.getItem("accesstoken") == null) {
		localStorage.clear();
		window.location.href = "login";
	}
};

export const staticSort = (list, type) => {
	list?.sort((a, b) => {
		if (a[type].toLowerCase() < b[type].toLowerCase()) return -1;
		if (a[type].toLowerCase() > b[type].toLowerCase()) return 1;
		return 0;
	});
	return list; // Add this line to return the sorted list
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fixDate = (dateString, short = false, special = false) => {
	let date = new Date(dateString);
	let cleaned = "";
	if (!dateString) {
		return "";
	}

	if (special) {
		const pad = (num) => num.toString().padStart(2, "0");
		let year = date.getFullYear();
		let month = pad(date.getMonth() + 1); // getMonth() returns 0-11
		let day = pad(date.getDate());
		let hours = pad(date.getHours());
		let minutes = pad(date.getMinutes());

		cleaned = `${year}-${month}-${day} ${hours}:${minutes}`;
	} else if (short) {
		cleaned = date.toLocaleDateString();
	} else {
		cleaned = date.toLocaleString();
	}
	return cleaned;
};

export const fixDateEvent = (dateString, day = true) => {
	let date = new Date(dateString);
	if (day) {
		return date.toLocaleDateString();
	} else {
		const pad = (num) => num.toString().padStart(2, "0");
		let year = date.getFullYear();
		let month = pad(date.getMonth() + 1); // getMonth() returns 0-11
		let day = pad(date.getDate());
		let hours = pad(date.getHours());
		let minutes = pad(date.getMinutes());
		return `${hours}:${minutes}`;
	}
};


export const translateReverse = (t, key) => {
	const searchKey = key.toLowerCase();
	const matchingEvents = []; // Array to store matching events

	const eventTypes = [
		"deviceMoving", "deviceOnline", "deviceStopped", "deviceOffline",
		"geofenceExit", "geofenceEnter", "tampering", "lowBattery",
		"lowExternalBattery", "internal_low_battery", "ignitionOn", 
		"ignitionOff", "vibration", "powerCut", "sos", "deviceOverspeed", 
		"deviceUnknown", "bypass", "external_engineBlockOn", 
		"external_engineBlockOff", "test_failed", "retest_failed", 
		"initial_test_passed", "retest_passed", "deviceError", "alarmcenter",
		"psmsafetyzone", "powerOn", "powerOff", "deviceChargeOn", 
		"deviceChargeOff", "deviceWake", "deviceSleepOn", "deviceLightOn", 
		"deviceLightOff", "deviceTempExceeded", "deviceTempBelow", 
		"deviceHumExceeded", "deviceHumBelow"
	];

	eventTypes.forEach(eventType => {
		const eventKey = `events_raw.${eventType}`;
		if (t(eventKey).toLowerCase().includes(searchKey.toLowerCase())) {
			matchingEvents.push(eventType);
		}
	});

	return matchingEvents.length ? matchingEvents : null; // Return matching events array or null if no match found
};


export const translateEvents = (t, event) => {
	switch (event) {
		case "deviceMoving":
			return t("events.device_moving");
		case "deviceOnline":
			return t("events.device_online");
		case "deviceStopped":
			return t("events.device_stopped");
		case "deviceOffline":
			return t("events.device_offline");
		case "geofenceExit":
			return t("events.geofence_exit");
		case "geofenceEnter":
			return t("events.geofence_enter");
		case "tampering":
			return t("events.tampering");
		case "lowBattery":
			return t("events.low_battery");
		case "lowExternalBattery":
			return t("events.low_external_battery");
		case "internal_low_battery":
			return t("events.low_battery");
		case "ignitionOn":
			return t("events.ignition_on");
		case "ignitionOff":
			return t("events.ignition_off");
		case "vibration":
			return t("events.vibration");
		case "powerCut":
			return t("events.power_cut");
		case "sos":
			return t("events.sos");
		case "deviceOverspeed":
			return t("events.device_overspeed");
		case "deviceUnknown":
			return t("events.device_unknown");
		case "bypass":
			return t("events.bypass");
		default:
			return event;
	}
};

export const getEventIcon = (event) => {
	switch (event) {
		case "deviceMoving":
			return icons.event_icons.moving;
		case "deviceOnline":
			return icons.event_icons.online;
		case "deviceStopped":
			return icons.event_icons.stopped;
		case "deviceOffline":
			return icons.event_icons.offline;
		case "geofenceExit":
			return icons.event_icons.geo_exit;
		case "geofenceEnter":
			return icons.event_icons.geo_enter;
		case "tampering":
			return icons.event_icons.tampering;
		case "lowBattery":
			return icons.event_icons.low_battery;
		case "lowExternalBattery":
			return icons.event_icons.low_external_battery;
		case "internal_low_battery":
			return icons.event_icons.low_battery;
		case "ignitionOn":
			return icons.event_icons.ignition_on;
		case "ignitionOff":
			return icons.event_icons.ignition_off;
		case "vibration":
			return icons.event_icons.vibration;
		case "powerCut":
			return icons.event_icons.power_cut;
		case "sos":
			return icons.event_icons.sos;
		case "deviceOverspeed":
			return icons.event_icons.overspeed;
		case "deviceUnknown":
			return icons.event_icons.unknown;
		case "alarmcenter":
			return icons.event_icons.central;
		case "psmsafetyzone":
			return icons.event_icons.central;
		case "powerOn":
			return icons.event_icons.charge_on;
		case "powerOff":
			return icons.event_icons.power_cut;
		case "deviceChargeOn":
			return icons.event_icons.charge_on;
		case "deviceChargeOff":
			return icons.event_icons.power_cut;
		case "deviceWake":
			return icons.event_icons.wakeup;
		case "deviceSleepOn":
			return icons.event_icons.sleep;
		case "deviceLightOn":
			return icons.event_icons.light_on;
		case "deviceLightOff":
			return icons.event_icons.light_off;
		case "deviceTempExceeded":
			return icons.event_icons.temp_exceed;
		case "deviceTempBelow":
			return icons.event_icons.temp_below;
		case "deviceHumExceeded":
			return icons.event_icons.hum_exceed;
		case "deviceHumBelow":
			return icons.event_icons.hum_below;
		case "bypass":
			return null;
		case "external_engineBlockOn":
			return null;
		case "external_engineBlockOff":
			return null;
		case "test_failed":
			return null;
		case "retest_failed":
			return null;
		case "initial_test_passed":
			return null;
		case "retest_passed":
			return null;
		case "deviceError":
			return null;
		default:
			return icons.event_icons.unknown;
	}
};

export const getEventIconMap = (event) => {

	switch (event) {
		case "deviceMoving":
			return icons.event_map_icons.moving;
		case "deviceOnline":
			return icons.event_map_icons.online;
		case "deviceStopped":
			return icons.event_map_icons.stopped;
		case "deviceOffline":
			return icons.event_map_icons.offline;
		case "geofenceExit":
			return icons.event_map_icons.geo_exit;
		case "geofenceEnter":
			return icons.event_map_icons.geo_enter;
		case "tampering":
			return icons.event_map_icons.tampering;
		case "lowBattery":
			return icons.event_map_icons.low_battery;
		case "lowExternalBattery":
			return icons.event_map_icons.low_external_battery;
		case "internal_low_battery":
			return icons.event_map_icons.low_battery;
		case "ignitionOn":
			return icons.event_map_icons.ignition_on;
		case "ignitionOff":
			return icons.event_map_icons.ignition_off;
		case "vibration":
			return icons.event_map_icons.vibration;
		case "powerCut":
			return icons.event_map_icons.power_cut;
		case "sos":
			return icons.event_map_icons.sos;
		case "deviceOverspeed":
			return icons.event_map_icons.overspeed;
		case "deviceUnknown":
			return icons.event_map_icons.unknown;


		case "alarmcenter":
			return icons.event_map_icons.central;
		case "psmsafetyzone":
			return icons.event_map_icons.central;
		case "powerOn":
			return icons.event_map_icons.charge_on;
		case "powerOff":
			return icons.event_map_icons.power_cut;
		case "deviceChargeOn":
			return icons.event_map_icons.charge_on;
		case "deviceChargeOff":
			return icons.event_map_icons.power_cut;
		case "deviceWake":
			return icons.event_map_icons.wakeup;
		case "deviceSleepOn":
			return icons.event_map_icons.sleep;
		case "deviceLightOn":
			return icons.event_map_icons.light_on;
		case "deviceLightOff":
			return icons.event_map_icons.light_off;
		case "deviceTempExceeded":
			return icons.event_map_icons.temp_exceed;
		case "deviceTempBelow":
			return icons.event_map_icons.temp_below;
		case "deviceHumExceeded":
			return icons.event_map_icons.hum_exceed;
		case "deviceHumBelow":
			return icons.event_map_icons.hum_below;
		case "bypass":
			return null;
		case "external_engineBlockOn":
			return null;
		case "external_engineBlockOff":
			return null;
		case "test_failed":
			return null;
		case "retest_failed":
			return null;
		case "initial_test_passed":
			return null;
		case "retest_passed":
			return null;
		case "deviceError":
			return null;
		default:
			return icons.event_map_icons.unknown;
	}
};

export const translateLanguages = (t, lang) => {
	switch (lang) {
		case "en" || "english":
			return t("language.english");
		case "sv" || "swedusg":
			return t("language.swedish");

		default:
			return lang;
	}
};

export const downloadPDF = (url, newPage = true) => {
	const link = document.createElement("a");
	if (newPage) {
		link.target = "_blank";
	}
	link.href = url;
	link.setAttribute("download", true); //or any other extension
	document.body.appendChild(link);
	link.click();
};

export const getMapMarker = (marker, online) => {
	if(process.env.REACT_APP_FLAVOR === "bike"){
		if(online){
			return markers.device_online
		}else{
			return markers.device_offline
		}
	}

	let [type, color] = marker.split("_");

	if (markers[color] && markers[color][type]) {
		if (!online) {
			return markers["grey"][type];
		} else {
			return markers[color][type];
		}
		// Return the corresponding marker
	} else {
		if (!online) {
			return markers["grey"]["default"];
		} else {
			return markers["blue"]["default"];
		}
		// Return the default marker (blue in this case) if not found
	}
};

export const copyToClipboard = (data) => {
	return new Promise((resolve, reject) => {
		navigator.clipboard
			.writeText(data)
			.then(() => {
				resolve("success");
			})
			.catch((error) => {
				console.error("Copy failed:", error);
				reject("failed");
			});
	});
};

export const expandMap = () => {
	const map = document.querySelector(".map_style");
	const mapExpanded = document.querySelector(".map_style_expanded");
	const info = document.querySelector(".section-left_bottom_data");
	const infoExpanded = document.querySelector(".section-left_bottom_data_expanded");
	const statsCard = document.querySelector(".map_statistics_detail");
	const statsCardExtended = document.querySelector(".map_statistics_detail_expanded");
	if (map === null) {
		infoExpanded.classList.replace("section-left_bottom_data_expanded", "section-left_bottom_data");
		if (mapExpanded) {
			mapExpanded.classList.replace("map_style_expanded", "map_style");
		}
		if (statsCardExtended) {
			statsCardExtended.classList.replace("map_statistics_detail_expanded", "map_statistics_detail");
		}
	} else {
		info.classList.replace("section-left_bottom_data", "section-left_bottom_data_expanded");
		if (map) {
			map.classList.replace("map_style", "map_style_expanded");
		}
		if (statsCard) {
			statsCard.classList.replace("map_statistics_detail", "map_statistics_detail_expanded");
		}
	}
};

export const getBatteryIcon = (value) => {
	if (value > 95) {
		return icons.battery.battery_100;
	} else if (value > 70) {
		return icons.battery.battery_75;
	} else if (value > 40) {
		return icons.battery.battery_50;
	} else if (value > 20) {
		return icons.battery.battery_25;
	} else {
		return icons.battery.battery_0;
	}
};

export const getBatteryIconHori = (value) => {

	if (value > 95) {
		return icons.battery_full;
	} else if (value > 70) {
		return icons.battery_three_quarter;
	} else if (value > 40) {
		return icons.battery_half;
	} else if (value > 20) {
		return icons.battery_quarter;
	} else {
		return icons.battery_empty;
	}
};


export const externalPowerText = (model) => {
    switch (model) {
        case 7:
        case 8:
        case 9:
        case 10:
        case 12:
        case 14:
            return "connected";
        default:
            return "charging";
    }
};


export function getCssVariableValue(variableName) {
    // Get the root element (HTML document)
    const root = document.documentElement;

    // Get the computed style of the root element
    const computedStyle = getComputedStyle(root);

    // Fetch and return the value of the custom property
    return computedStyle.getPropertyValue(variableName);
}

