import React, { useState, useEffect } from "react";
import { INDEX_URL, CUST, icons } from "../helpers/constants";
import Button from "../components/Buttons/Button";
import Searchbar from "../components/Searchbar/Searchbar";
import { useTranslation } from "react-i18next";
import * as request from "../services/FetchService";
import GeofenceRow from "../components/Geofences/GeofenceRow";
import { MapTogglesProvider, useMapToggles } from '../components/Map/ToggleContext'; // Adjust the path as necessary
import { useContextData } from '../components/ContextData';
import DeviceSelectionRow from "../components/Devices/DeviceSelectionRow";
import { Card, Collapse } from "@mui/material";
import DragComponent from "../components/DragComponent";
import toast from "react-hot-toast";
import { staticSort } from "../helpers/helperFunctions";

const Geofence = (props) => {
	const { t } = useTranslation();
	const { deviceListCompact, setDeviceListCompact } = useContextData();
	const [showActions, setShowActions] = useState(false)
	const [deleteToggle, setDeleteToggle] = useState(false)
	const [deleteAllGeo, setDeleteAllGeo] = useState(false)
	const [showAddDevice, setShowAddDevice] = useState(false);
	const [geofenceList, setGeofenceList] = useState([])
	const [geofenceListFull, setGeofenceListFull] = useState([])
	const [createGeofence, setCreateGeofence] = useState(false)
	const [errorText, setErrorText] = useState("")
	const [geoName, setGeoName] = useState("")
	const [chooseAll, setChooseAll] = useState(false)
	const [deviceList, setDeviceList] = useState([])
	const [deviceListFull, setDeviceListFull] = useState([])
	const [checkedDevices, setCheckedDevices] = useState([])
	const [currentFence, setCurrentFence] = useState()
	const [allSelected, setAllSelected] = useState(false)
	const [loading, setLoading] = useState(true)
	const { creatingGeo, setCreatingGeo, setContextGeoMethod, contextGeoMethod, geoData, setGeoData, setContextSaveGeofence, setInitialGeoData, setGeofenceDeleted, setGeofenceCreated } = useMapToggles();
	const [toggleDrag, setToggleDrag] = useState(false)

	useEffect(() => {
		getGeofences()
		getDevices()
	}, []);

	const getGeofences = () => {
		request.get("geofence/info").then((response) => {
			let sorted = staticSort(response.data, "name");
			setGeofenceList(sorted)
			setGeofenceListFull(sorted)
			setLoading(false)
		});	
	}

	const getDevices = () => {
		if(deviceListCompact.length > 0){
			setDeviceListFull(deviceListCompact)
		}else{
			request.get("devices/compactinfo").then((response) => {
				let sorted = staticSort(response.data.devices, "name")
				setDeviceListFull(sorted)
				setDeviceListCompact(sorted)
			});
		}
	};
	useEffect(() => {
		let sorted = staticSort(deviceListFull, "name")
		setDeviceList(sorted)
		
	}, [deviceListFull])

	const handleChange = (field, e) => {
        setErrorText("")
        if (field === "name"){
            setGeoName(e.target.value);
		};
    }
	useEffect(() => {
		setCreatingGeo(createGeofence)
		if(!createGeofence){
			closeActions()
		}
	}, [createGeofence])

	const closeActions = () => {
		setCreateGeofence(false)
		setCheckedDevices([])
		setContextGeoMethod("poly")
		setCreatingGeo(false)
		setCurrentFence(null)
		setGeoName("")
		setInitialGeoData(null)
		setShowActions(false)
		setDeleteToggle(false)
		let updatedGeo = [...geofenceList];
		updatedGeo.map((fence, index) => {
			fence.selected = false
		})
		let updatedDevices = [...deviceListFull];
		updatedDevices.map((device, index) => {
			device.selected = false
		})
		setGeofenceList(updatedGeo)
	}

	const saveGeofence = () => {
		setContextSaveGeofence(true)
	}

	useEffect(() => {
		if(geoData){
			let data = {}
			if(currentFence){
				let geoPos = geoData;
				if(!geoPos.center){
					geoPos = geoData.map(location => ([
						location.lat,
						location.lng
					]));
				}
				data = {
					geofenceid: currentFence.id,

					name: geoName,
					deviceid: checkCheckedDevices(),
					type: contextGeoMethod === "poly" ? "POLYGON" : "CIRCLE",
					...(contextGeoMethod === "poly" 
						? { points: geoPos }
						: {
							latitude: geoPos.center.lat,
							longitude: geoPos.center.lng,
							radius: geoPos.radius
						} 
					)
				}
				request.post("", "geofence/update", data).then((response) => {
					if(response.data.successful){
						closeActions()
						setGeofenceCreated(true)
						getGeofences()
						setGeoData()
						toast.success(t("toast.done"))
					}else{
						toast.error(t("toast.error"))
					}
				})
			}else{
				let geoPos = geoData;
				if(!geoPos.center){
					geoPos = geoData.map(location => ([
						location.lat,
						location.lng
					]));
				}
				data = {
					name: geoName,
					deviceid: checkCheckedDevices(),
					type: contextGeoMethod === "poly" ? "POLYGON" : "CIRCLE",
					...(contextGeoMethod === "poly" 
						? { points: geoPos }
						: {
							latitude: geoPos.center.lat,
							longitude: geoPos.center.lng,
							radius: geoPos.radius
						} 
					)
				}
				request.post("", "geofence/create", data).then((response) => {
					if(response.data.successful){
						closeActions()
						setGeofenceCreated(true)
						getGeofences()
						setGeoData()
						toast.success(t("toast.done"))
					}else{
						toast.error(t("toast.error"))
					}
				})
			}
		}
		closeActions()
		setContextSaveGeofence(false)
	}, [geoData])

	const editGeofence = (fence) => {
		setCreateGeofence(!createGeofence)
		setCurrentFence(fence)
	}

	useEffect(() => {
		if(currentFence){
			setGeoName(currentFence.name)
			if(currentFence.type === "POLYGON"){
				setInitialGeoData(currentFence.points)
				setContextGeoMethod("poly")

			}else{
				let data = {
					radius: currentFence.radius,
					center: {lat: currentFence.latitude, lng: currentFence.longitude}
				}
				setInitialGeoData(data)
				setContextGeoMethod("circle")
			}
			deviceListFull.map((device, index) => {
				if (currentFence.deviceid.includes(device.id)) {
					selectDevice(index)
				}
			});
			setToggleDrag(true)
		}else{
            deviceListFull.map((device, index) => {
				device.selected = false
            })
		}
	}, [currentFence])

	useEffect(() => {
		if(deleteAllGeo){
			let updatedGeo = [...geofenceList];
            updatedGeo.map((fence, index) => {
				fence.selected = true
            })
			setGeofenceList(updatedGeo)
		}else{
			let updatedGeo = [...geofenceList];
            updatedGeo.map((fence, index) => {
				fence.selected = false
            })
			setGeofenceList(updatedGeo)
		}
    }, [deleteAllGeo])


	const selectGeofence = (index) => {
        let updatedGeo = [...geofenceList];
        if (index !== -1) {
            var fence = updatedGeo[index];
            if (fence.selected !== true){
                fence.selected = true;
            }else{
                fence.selected = false;
            } 
            updatedGeo[index] = fence;
            setGeofenceList(updatedGeo);
          }
    }


    useEffect(() => {
		if(allSelected){
			let updatedDevices = [...deviceListFull];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setDeviceListFull(updatedDevices)
		}else{
			let updatedDevices = [...deviceListFull];
            updatedDevices.map((device, index) => {
				device.selected = false
            })
			if(updatedDevices.length > 0){
				setDeviceListFull(updatedDevices)
			}
		}
    }, [allSelected])

	const selectDevice = (index) => {
        let updatedDevices = [...deviceListFull];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (!device.selected){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setDeviceListFull(updatedDevices);
          }
    }


	const renderCard = () => {
		return (
		<Card className={`py-0 px-1 mt-1 mb-2`}>
            <div className="d-flex flex-row justify-content-between align-items-center px-1 pt-3 pb-2 border-bottom">
                <h4 className="mb-2">{t("events.actions")}</h4>
                <img
                    className="mb-2 size_25 cursor-pointer close_img"
                    src={icons.close_gray}
                    onClick={() =>setShowActions(!showActions)}
                    alt="..."
                />
            </div>
			<ul className="list-group list-group-flush">
				<li
					className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
					onClick={() => {setDeleteToggle(!deleteToggle)}}
				>
				<p className={`my-2 ${deleteToggle ? "primary" : "dark_grey"}`}>
					{t("geofence.delete_geofence")}
				</p>
				</li>
			</ul>
        </Card>
		)
	}

	const filterSearch = (searchWord) => {
		let array;
		let match;

		if(createGeofence){
			array = deviceListFull
			if((!searchWord || searchWord === "") && deviceListFull.length>0){
				setDeviceList(deviceListFull)
				return
			}
			match = array.filter((device) => {
			const item = searchWord.toLowerCase();
			return (
				device.name.toLowerCase().indexOf(item) > -1 ||
				device.uniqueid.toLowerCase().indexOf(item) > -1
				);
			})
			setDeviceList(match)
		}else{
			array = geofenceListFull
			if(!searchWord || searchWord === ""){
				setGeofenceList(geofenceListFull)
				return
			}
	
			match = array.filter((fence) => {
			const item = searchWord.toLowerCase();
			return (
				fence.name.toLowerCase().indexOf(item) > -1
				);
			})
			setGeofenceList(match)
		}

	}

	const checkCheckedDevices = () => {
		let checkedDevices = deviceListFull.filter(device => device.selected).map(device => device.id);
		return checkedDevices
	}


	const checkCheckedFences = () => {
		let checked = geofenceList.filter(fence => fence.selected).map(fence => parseInt(fence.id));
		return checked
	}

	const deleteGeofence = () => {
		checkCheckedFences().map((fence, index) => {
			request.post("", "geofence/delete", {geofenceid: fence}).then((response) => {
				if(response.data.successful){
					setGeofenceDeleted(true)
					getGeofences()
					closeActions()
					setInitialGeoData(null)
					toast.success(t("toast.done"))
				}else{
					toast.error(t("toast.error"))
				}
			})
		})
	}

	useEffect(() => {
		if(createGeofence){
			setToggleDrag(true)
		}else{
			setToggleDrag(false)

		}
	}, [createGeofence])

	return (
		<>
			<div className="section-left_bottom_data" id="resizable-element">
				<DragComponent dragId="resizable-element" initialH="60px" maxH={80} minH="60px" close={toggleDrag} setClose={() => setToggleDrag(false)}/>

				<div className="d-flex flex-row mt-4 align-items-center page_header">
					<img src={icons.sidebar.geofence_active} className="title_icon" alt="..." />
					<h1 className="h1 primary ms-3">{t("geofence.geofences")}</h1>
				</div>
		
					{/* Add device */}
					<Button className="my-3" onClick={() => setCreateGeofence(!createGeofence)} btnClass={createGeofence ? "btn-selected" : "btn-left-section"} icon={icons.plus}>
						{t("geofence.create_geofence")}			
					</Button>
					{createGeofence 
					?
					<>
					<div className="d-flex flex-column">
						<h3 className="">{currentFence ? t("geofence.edit_geofence") : t("geofence.create_geofence")}</h3>
						<div className="d-flex flex-row flex-fill my-3">
							<div className="d-flex flex-column w-50">
								<h4 className="">{t("geofence.name")}</h4>
								<form>
									<div className="form-group d-flex align-items-center">
										<input
											type="text"
											className="form-control bg-grey"
											id="name"	
											value={geoName}
											onChange={(e) => {
												handleChange("name", e);
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
												e.preventDefault();// Prevent the default Enter key behavior
												
												}
											}}
										/>
										<div className="invalid-feedback ms-1 bottom">
											{errorText === "" ? "" : errorText}
										</div>
									</div>
								</form>
							</div>
							<div className="d-flex flex-column ms-3">
								<h4 className="">{t("geofence.method")}</h4>
								<div className="d-flex flex-row">
									<Button className="me-3" btnClass={contextGeoMethod === "poly" ? "btn-primary" : "btn-grey"} notext={true} icon={icons.polygon} onClick={() => setContextGeoMethod("poly")}/>
									<Button notext={true} btnClass={contextGeoMethod === "circle" ? "btn-primary" : "btn-grey"} icon={icons.geofence} onClick={() => setContextGeoMethod("circle")}/>
{/* 
									<img className="me-3" src={contextGeoMethod === "poly" ? icons.poly_selected : icons.poly_unselected} alt="" onClick={() => setContextGeoMethod("poly")} />
									<img src={contextGeoMethod === "circle" ? icons.circle_selected : icons.circle_unselected} alt="" onClick={() => setContextGeoMethod("circle")}/> */}
								</div>
							</div>
						</div>
						<div className="d-flex flex-column">
							<h4 className="mb-2">{t("geofence.choose_device")}</h4>
							<Searchbar className="mb-3" submitSearch={(data) => filterSearch(data)} />
							{deviceList.length > 0 
								?
								<div className={`geofence_device_list scrollable-y shadow-small ${(!deviceListFull.some(device => device.selected) || geoName === "") ? "" : "pushed"}`}>
									<DeviceSelectionRow 
										text={t("devices.choose_all")}
										index={-1}
										selected={deviceListFull.some(device => !device.selected) ? false : true}
										onClick={() => setAllSelected(!allSelected)}
									/>
									{deviceList.map((device, index) => (
										<DeviceSelectionRow
											key={index}
											index={index}
											text={device.name}
											selected={device.selected ? true : false}
											onClick={() => selectDevice(index)}
										/>
									))}
								</div>
								:
								<h3 className="bold dark-grey text-center mt-5">{t("geofence.no_devices")}</h3>
							}
							<div className={`d-flex justify-content-end mt-3 ${(!deviceListFull.some(device => device.selected) || geoName === "") && "d-none"}`}>
								<Button btnClass="btn-secondary me-3" onClick={() => closeActions()}>{t("geofence.cancel")}</Button>
								<Button btnClass="btn-primary" onClick={() => saveGeofence()}>{t("geofence.save")}</Button>
							</div>
						</div>
					</div>
					</>
					:
					<>
					<Searchbar submitSearch={(data) => filterSearch(data)} />
					{/* Actions */}
					<div className="position-relative my-3 d-flex">
					
						<div className={`me-3 align-self-center ${!deleteToggle ? "d-none" : !geofenceList.some(fence => !fence.selected) ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={() => setDeleteAllGeo(!deleteAllGeo)}>
							{!geofenceList.some(fence => !fence.selected) && <img className="white-icon" src={icons.check} alt={""} />}
						</div>
						<Button btnClass="btn-left-section" onClick={() => setShowActions(!showActions)} icon={icons.plus}>
							{t("geofence.actions")}
						</Button>
						<Collapse in={showActions} className="position-absolute top-100 w-50">
							{renderCard()}
						</Collapse>
					</div>
					<div className={`geofence_list ${deleteToggle ? "pushed" : ""} scrollable-y shadow-small`}>
						{geofenceList.map((fence, index) => (
							<GeofenceRow geofence={fence} index={index} key={index} focusOnMap={() => setToggleDrag(true)} checkboxVisibility={deleteToggle} setGeofenceToggle={() => selectGeofence(index)} checkedFences={[]} editGeofence={(data) => editGeofence(data)}/>
						))}
					</div>
					{(!geofenceList || geofenceList.length === 0) && !loading &&
					<h3 className="regular text-center mt-5">{t("geofence.no_geofences")}</h3>
				}
					<div className={`d-flex justify-content-end mt-3 ${!deleteToggle && "d-none"}`}>
						<Button btnClass="btn-secondary me-3" onClick={() => closeActions()}>{t("geofence.cancel")}</Button>
						<Button btnClass="btn-primary" onClick={() => deleteGeofence()}>{t("geofence.delete_geofence")}</Button>
					</div>
					</>
					}

			</div>
		</>
	);
};

export default Geofence;
