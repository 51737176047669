import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Devices from '../../pages/Devices';
import Events from '../../pages/Events';
import Geofence from '../../pages/Geofence';
import History from '../../pages/History';
import Subscriptions from '../../pages/Subscriptions';
import Settings from '../../pages/Settings';
import BikeRoutes from '../../pages/BikeRoutes';
import Statistics from '../../pages/Statistics';
import EventsAlco from "../../pages/EventsAlco";
import SidebarMobile from '../Sidebar/SidebarMobile';
import Sidebar from '../Sidebar/Sidebar';
import { useIsMobile } from '../../helpers/customHooks';
import { MapTogglesProvider } from '../Map/ToggleContext';
import { FLAVOR } from '../../helpers/constants';
import Map from '../Map/Map';
import { useContextData } from '../../components/ContextData';
import Login from '../../pages/Login';
import Signup from '../../pages/Signup';
import SignupInvite from '../../pages/SignupInvite';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import SubscriptionsInterim from '../../pages/SubscriptionsInterim';


const Routing = (props) => {
	const MOBILE = useIsMobile();
    const location = useLocation();
    const { accessToken } = useContextData();

    const trackingRoutes = () => {
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<Events />} />
			<Route path={"/geofence"} element={<Geofence />} />
			<Route path={"/history"} element={<History />} />
			<Route path={"/subscriptions"} element={<SubscriptionsInterim />} /> {/* OLD DATAPLAN */}
			{/* <Route path={"/subscriptions"} element={<Subscriptions />} /> NEW DATAPLAN */}
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const bikeRoutes = () => {
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<Events />} />
			<Route path={"/geofence"} element={<Geofence />} />
			<Route path={"/history"} element={process.env.REACT_APP_ASSETS === "rawbike" || process.env.REACT_APP_ASSETS === "livelo" ? <History /> : <BikeRoutes />} />
			{process.env.REACT_APP_ASSETS !== "rawbike" && <Route path={"/statistics"} element={<Statistics />} />}
			{/* <Route path={"/subscriptions"} element={<Subscriptions />} /> */}
			<Route path={"/subscriptions"} element={<SubscriptionsInterim />} /> {/* OLD DATAPLAN */}
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const alcoRoutes = () => {
		return(
		<Routes>
			<Route path={"/devices"} element={<Devices />} />
			<Route path={"/events"} element={<EventsAlco />} />
			<Route path={"/settings"} element={<Settings />} />
			<Route path="*" element={<Navigate to="/devices" replace />} />
		</Routes>
		)
	}
	const loggedOutRoutes = () => {
		return(
		<Routes>
			<Route path={"/login"} element={<Login />} />
			<Route path={"/signup"} element={<Signup />} />
			<Route path={"/signup/:token"} element={<SignupInvite />} />
			<Route path={"/forgot"} element={<ForgotPassword />} />
			<Route path={"/reset/:token"} element={<ResetPassword />} />
			<Route path="*" element={<Navigate to="/login" replace />} />
		</Routes>
		)
	}
	
    return(
		<>
        {accessToken 
        ?
        <>
        {MOBILE ? <SidebarMobile /> : <Sidebar />}
		<div className={`d-flex flex-row section-data`}>
			<MapTogglesProvider>
				{FLAVOR === "tracking"
					? trackingRoutes()
					: FLAVOR === "bike"
					? bikeRoutes()
					: FLAVOR === "alco"
					? alcoRoutes()
					: null
				}
                {(location.pathname !== "/settings" && location.pathname !== "/subscriptions" && location.pathname !== "/statistics") && 
                    <Map/>		
                }
			</MapTogglesProvider>
		</div>
		</>
        :
		loggedOutRoutes()
        }
</>
		)
}

export default Routing;