import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, STRIPE_TEST_KEY } from "../helpers/constants";
import { Collapse } from '@mui/material';
import Searchbar from '../components/Searchbar/Searchbar';
import { useTranslation } from "react-i18next";
import Button from '../components/Buttons/Button';
import * as request from "../services/FetchService";
import { capitalizeFirstLetter, fixDate, staticSort } from '../helpers/helperFunctions';
import ReceiptRow from '../components/Subscription/ReceiptRow';
import ActiveRowInterim from '../components/Subscription/ActiveRowInterim';
import InactiveRow from '../components/Subscription/InactiveRow';
import MonthYearSegment from '../components/PeriodSegment/PeriodSegement';
import DeviceSelectionRow from '../components/Devices/DeviceSelectionRow';
import DeviceActivationList from '../components/Devices/DeviceActivationList';
import AddCard from '../components/Subscription/Stripe/AddCard';
import CardRow from '../components/Subscription/Stripe/CardRow';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionModal from '../components/Modals/SubscriptionModal';
import { useIsMobile } from '../helpers/customHooks';
import Activating from '../components/Subscription/Activating';
import Deactivating from '../components/Subscription/Deactivating';
import Modify from '../components/Subscription/Modify';
import EditCardModal from '../components/Modals/EditCardModal';
import FailedRow from '../components/Subscription/FailedRow';
import FailedPaymentModal from '../components/Modals/FailedPaymentModal';
import ActivatingInterim from '../components/Subscription/ActivatingInterim';
import DeactivatingInterim from '../components/Subscription/DeactivatingInterim';


const SubscriptionsInterim = (props) => {
	const MOBILE = useIsMobile();
	const { t } = useTranslation();
	const [receipts, setReceipts] = useState([])
	const [activeDevices, setActiveDevices] = useState([])
	const [failedDevices, setFailedDevices] = useState([])
	const [inactiveDevices, setInactiveDevices] = useState([])
	const [activatableDevices, setActivatableDevices] = useState([])
	const [deactivatableDevices, setDeactivatableDevices] = useState([])
	const [activating, setActivating] = useState(false)
	const [deactivating, setDeactivating] = useState(false)
	const [modify, setModify] = useState(false)
	const [editCard, setEditCard] = useState(false)
	const [cards, setCards] = useState([])
	const [showFailed, setShowFailed] = useState(false)

    const stripePromise = loadStripe(STRIPE_TEST_KEY);

    useEffect(() => {
        getDevices()
        getReceipts()
        getCards()
    }, [])

	const flowFinsished = () => {
		setModify(false)
		setActivating(false)
		setDeactivating(false)
		getDevices()
		// newApi()
	}

	const handleFailed = () => {
		setShowFailed(true)
	}

    const getDevices = () => {
        request.get("dataplan/list/all").then((response) => {
			if(response.data){
				setActiveDevices(response.data.active)
				setActivatableDevices(response.data.inactive)
				setInactiveDevices(response.data.inactive)
				setDeactivatableDevices(response.data.active)
			}
        })
    }

    const getReceipts = () => {
        request.get("dataplan/receipts").then((response) => {
			if(response.data){
				setReceipts(response.data.data)
			}
        })
    }

    const getCards = () => {
        request.get("dataplan/cards/getall").then((response) => {
			if(response.data){
				setCards(response.data.data)
			}
        })   
    }


    const deleteCard = () => {
        let data = { cardid: cards[0].id}
        request.post("", "dataplan/cards/delete", data).then((response) => {
            if(response){
				setEditCard(false)
                getCards()
            }
        })
    }


	return(
		<>
		<div className={`d-flex flex-column px-3 w-100 scrollable-y ${MOBILE ? "py-5" : ""}`}>
			<div className="d-flex flex-row mt-4 align-items-center page_header">
 						<img src={icons.sidebar.card_active} className="title_icon primary-icon" alt="..." />
						<h1 className="h1 primary ms-3">{t("subscription.subscription")}</h1>
				</div>
			{!activating && !deactivating && !modify &&
			<div className={`d-flex ${MOBILE ? "flex-column w-100 h-100 mt-5" : "flex-row mt-3"} justify-content-between`}>
				<div className="w-100 me-3">
					<div className="d-flex flex-row align-items-center mb-1 primary">
						<h3 className={`me-2`}>{t("subscription.overview")}</h3>
						{/* <p className="me-2 underline cursor-pointer" onClick={() => setModify(true)}>{t("subscription.modify")}</p> */}
						<p className="underline cursor-pointer" onClick={() => setEditCard(true)}> {cards && cards.length > 0 ? t("subscription.edit_card") : t("subscription.add_card")}</p>
					</div>

					<div className={`d-flex ${MOBILE ? "flex-column w-100" : "flex-row"} justify-content-between primary`}>
						<div className="card w-100 d-flex flex-column p-3 shadow-small me-1 justify-content-between">
							<img className="size_25" src={icons.plus} alt=""/>
							<div className="d-flex flex-row justify-content-between align-items-end">
								<div className="d-flex flex-column">
									<p className="dark-grey mt-3">{t("subscription.active")}</p>
									<h1 className="">{activeDevices?.length}</h1>
								</div>
								<Button btnClass={`btn-primary ${inactiveDevices?.length === 0 ? "disabled" : "" }`} onClick={() => setActivating(true)}>{t("subscription.activate")}</Button>
							</div>
						</div>
						<div className={`card w-100 d-flex flex-column p-3 shadow-small me-1 justify-content-between ${MOBILE ? "mt-3" : ""}`}>
							<img className="size_25" src={icons.cross} alt=""/>
							<div className="d-flex flex-row justify-content-between align-items-end">
								<div className="d-flex flex-column">
									<p className="dark-grey mt-3">{t("subscription.inactive")}</p>
									<h1>{inactiveDevices?.length}</h1>
								</div>
								<Button btnClass={`btn-danger ${activeDevices?.length === 0 ? "disabled" : "" }`} onClick={() => setDeactivating(true)}>{t("subscription.deactivate")}</Button>
							</div>
						</div>
					</div>
					<h3 className="mt-3 primary">{t("subscription.receipts")}</h3>
					{receipts?.length > 0 ?
					<>
						<div className={`row m-0 p-0 mt-2 mb-1 primary`}>
							<p className="col-5 bold">{t("subscription.date")}</p>
							<p className="col-5 bold">{t("subscription.type")}</p>
							<p className="col-2 bold d-flex justify-content-center">{t("subscription.download")}</p>
						</div>
						<div className={`receipt_list scrollable-y shadow-small`}>
							{receipts?.map((receipt, index) => (
								<ReceiptRow key={index} index={index} data={receipt} />
							))}
						</div>
					</>
					:
					<h4 className="regular mt-3 ms-3">{t("subscription.no_receipts")}</h4>
					}

				</div>
				<div className="w-100 d-flex flex-column primary">

					{failedDevices?.length > 0 &&
						<>
						<h3 className={`${MOBILE ? "mt-3" : ""}`}>{t("subscription.failed_payments")}</h3>
						<div className={`row m-0 p-0 d-flex align-items-center me-1 mt-2 px-2`}>
							<div className="row d-flex align-items-center mb-1">
								<p className="col-4 bold">{t("subscription.device")}</p>
								<p className="col-3 bold">{t("subscription.subscription")}</p>
								<p className="col-3 bold">{t("subscription.payment")}</p>
								<p className="col-2 bold">{t("subscription.actions")}</p>
							</div>
						</div>
						<div className={`subscription_device_list scrollable-y shadow-small`}>
							{activeDevices?.map((device, index) => (
								<FailedRow key={index} handleFailed={handleFailed} index={index} data={device} />
							))}
						</div>
						</>
					}
					<h3 className={`${(MOBILE || failedDevices?.length > 0) ? "mt-3" : ""}`}>{t("subscription.active_devices")}</h3>
					{activeDevices?.length > 0 ?
						<>
						<div className={`row m-0 p-0 d-flex align-items-center me-1 mt-2 px-2`}>
							<div className="row d-flex align-items-center mb-1">
								<p className="col-5 bold">{t("subscription.device")}</p>
								{/* <p className="col-3 bold">{t("subscription.subscription")}</p> */}
								<p className="col-3 bold">{t("subscription.payment")}</p>
								<p className="col-4 bold">{t("subscription.status")}</p>
							</div>
						</div>
						<div className={`subscription_device_list scrollable-y shadow-small`}>
							{activeDevices?.map((device, index) => (
								<ActiveRowInterim key={index} index={index} data={device} />
							))}
						</div>
						</>
						:
						<h4 className="regular mt-3 ms-3">{t("subscription.no_active_devices")}</h4>
					}
					<h3 className="mt-3">{t("subscription.inactive_devices")}</h3>
					{inactiveDevices?.length > 0 ?
					<>
					<div className={`row m-0 p-0 d-flex align-items-center me-1 mt-2 px-2`}>
						<div className="row d-flex align-items-center mb-1">
							<p className="col-6 bold">{t("subscription.device")}</p>
							<div className="col-1" />
							<p className="col-3 bold">{t("subscription.status")}</p>
							<p className="col-2 bold">{t("subscription.subscription")}</p>
						</div>
					</div>
					<div className={`subscription_device_list scrollable-y shadow-small ${MOBILE ? "mb-3" : ""}`}>
						{inactiveDevices?.map((device, index) => (
							<InactiveRow key={index} index={index} data={device} />
						))}
					</div>
					</>
					:
					<h4 className="regular mt-3 ms-3">{t("subscription.no_inactive_devices")}</h4>
					}

				</div>	
			</div>
			}
			{modify &&
				<Modify cards={cards} finalize={() => flowFinsished()} devices={deactivatableDevices} />
			}
			{
				activating &&
				<Elements stripe={stripePromise}>
					<ActivatingInterim cards={cards} updateCards={() => getCards()} finalize={() => flowFinsished()} devices={activatableDevices}/>
				</Elements>
			}
			{	
				deactivating &&
				<DeactivatingInterim finalize={() => flowFinsished()} devices={deactivatableDevices} />
			}
		</div>
        <EditCardModal show={editCard} cards={cards} updateCards={() => getCards()} deleteCard={(data) => deleteCard(data)} onClose={() => setEditCard(!editCard)} />
		<FailedPaymentModal show={showFailed} reason={"lol"} devices={"lol2"} cards={cards} onClose={() => setShowFailed(!showFailed)} />
		</>
	)
}
export default SubscriptionsInterim;