import React, { createRef, useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import { fixDate } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import SafetyModal from '../Modals/SafetyModal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import SafetyRow from '../Safety/SafetyRow';
import SafetyRowPlaceholder from '../Safety/SafetyRowPlaceholder';
import axios from 'axios';
import { useContextData } from '../ContextData';

const Safety = (props) => {
	const { t } = useTranslation();
    const [historyList, setHistoryList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState()
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const { setAccessToken, setDeviceListMap, setDeviceListCompact } = useContextData();
    const listInnerRef = createRef();
    const navigate = useNavigate();

    const onScroll = () => {
		if(listInnerRef.current){
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight && !loading) {
				if(totalPages > currentPage){
					setLoading(true)
					getLoginHistory(currentPage + 1)
					setCurrentPage(currentPage + 1)
					// setEventList(eventList => [...eventList, ...eventScrollList[page]])
				}
			}
		}
	}

    useEffect(() => {
		window.addEventListener('scroll', onScroll)
        getLoginHistory()

		return() => {
			window.removeEventListener('scroll', onScroll)
		}
	}, []);

    const getLoginHistory = (page = currentPage) => {
        let data = {
            page: page,
            limit: 100
        }
        request.post("", "v2/login/history", data).then((response) => {
            if(response.status === 200){
                setLoading(false)
                setHistoryList(historyList => [...historyList, ...response.data.data])
                setTotalPages(response.data.totalPages)
            }else{
                setLoading(false)
            }
        })
    }

    const openModal = (type) => {
        setType(type)
        setShowModal(!showModal)
    }

    const clearHistory = () => {
        let ids = historyList.map((history) => history.id);
        let data = {
            loginid: ids,
            deleteAll: true
        }
        request.post("", "login/delete", data).then((response) => {
            if(response.data){
                setHistoryList([])
                setShowModal(false)
                getLoginHistory()
                toast.success(t("toast.success"))

            }else{
                toast.error(t("toast.error"))
            }
        })
    }

    let cancelTokenSource = axios.CancelToken.source();
    // Function to cancel all ongoing requests
    const cancelOngoingRequests = () => {
        cancelTokenSource.cancel('Logout initiated: Cancelling all ongoing requests.');
        // Create a new CancelToken source for future requests
        cancelTokenSource = axios.CancelToken.source();
    };

    const logoutAll = () => {
        request.get("login/signOutAll").then((response) => {
            if(response.data){
                cancelOngoingRequests(); // Cancel ongoing requests
                localStorage.clear()
                setAccessToken(null)
                setDeviceListMap([])
                setDeviceListCompact([])
                navigate("/devices")
            }
        })
    }
    
    return(
        <>
        {/* <p className="thin">{t("settings.safety")}</p> */}
        <p className="mb-1 thin">{t("settings.login_history")}</p>
        <div ref={listInnerRef} onScroll={() => onScroll()} className="d-flex flex-column shadow-small safety_list scrollable-y">
            {loading &&
              <SafetyRowPlaceholder />
            }
            {historyList && historyList.map((history, index) => (
                <SafetyRow key={index} history={history} index={index} />
            ))}
        </div>
        {historyList && historyList.length === 0  && !loading &&
            <div className="p-3 w-100">
                <h3 className="regular text-center">{t("settings.no_history")}</h3>
            </div>
        }
        {historyList && !loading &&
            <div className="d-flex justify-content-end mt-3">
                <Button onClick={() => openModal("clear")}>{t("settings.clear_history")}</Button>
                <Button onClick={() => openModal("logout")} btnClass="btn-danger ms-2">{t("settings.logout_all_sessions")}</Button>
            </div>
            }
        <SafetyModal show={showModal} clearHistory={() => clearHistory()} logoutAll={() => logoutAll()} type={type} onClose={() => setShowModal(!showModal)} />

        </>
    )
}

export default Safety;