import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import * as request from "../../services/FetchService";
import toast from 'react-hot-toast';


const AddDevice = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [page, setPage] = useState(1)
    const [deviceName, setDeviceName] = useState("")
    const [vin, setVin] = useState("")
    const [errorText, setErrorText] = useState("")
    useEffect(() => {
        setShow(props.show)
    },[props.show])
    
    const handleClose = () => {
        setDeviceName("")
        setPage(1)
        setVin("")
        props.onClose()
      //  setShow(false)
    }

    const handleChange = (field, e) => {
        setErrorText("")
        if (field === "name"){
            setDeviceName(e.target.value);
        }else{
            setVin(e.target.value);
        };
    }
    const clickNext = () => {
        if (deviceName !== ""){
            setPage(2);
            setErrorText("")
        }else{
            setErrorText(t("devices.add_device_p1_error"))
        } 
    };
    const clickAdd = async() => {
        if(vin ===""){
            setErrorText(t("devices.add_device_p2_error"))
        }else{
            let data = {
                name: deviceName,
                Lang: localStorage.getItem("language") ? localStorage.getItem("language") : "en"
            }
            if(process.env.REACT_APP_FLAVOR === "bike"){
                data.vinnumber = vin
            }else{
                data.imeinumber = parseInt(vin)
            }
            request.post("", "device/create", data).then((response) => {
                if(response.data){
                    if(response.data.error){
                        toast.error(t("toast.error"))
                        setErrorText(response.data.message)
                    }else{
                        setTimeout(() => {
                            toast.success(t("toast.added"))
                            props.updateDevices()
                            handleClose()  
                        }, 500);
                        
                    }
                }
            })
        }

    }

    const renderFirstPage = () => {
        return (
            <>
                <h4 className="primary">{t("devices.add_device_p1_title")}</h4>
                <p className="my-3 primary">{t("devices.add_device_p1_body")}</p>
                <form>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2 p"
                            id="newName"
                            value={deviceName}
                            onChange={(e) => {
                                handleChange("name", e);
                            }}
                            placeholder={t("devices.type_name")}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();// Prevent the default Enter key behavior
                                clickNext()
                                }
                            }}
                        />
                        <div className="invalid-feedback ms-1 bottom mb-1">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
                <Button type="submit" btnClass="btn-primary mt-4 align-self-center w-75" id="next" onClick={() => clickNext()}>{t("devices.next")}</Button>


                <div className="dot_layout mt-3 align-self-center">
                    <img alt="Step" className="dot me-1" src={icons.dot_selected} />
                    <img alt="Step" className="dot ms-1" src={icons.dot_unselected} />
                </div>
            </>
        )
    }

    const renderSecondPage = () => {
        return (
            <>
                <h3 className='primary'>{t("devices.add_device_p2_title")}</h3>
                <p className="my-3 primary">{t("devices.add_device_p2_body")}</p>
                <form>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2 p"
                            id="vin"
                            value={vin}
                            onChange={(e) => {
                                handleChange("vin", e);
                            }}
                            placeholder={process.env.REACT_APP_FLAVOR == "bike" ? "VIN" : "IMEI"}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();// Prevent the default Enter key behavior
                                  clickAdd() 
                                }
                              }}
                        />
                        <div className="invalid-feedback ms-1 bottom mb-1">
                            {errorText === "" ? "" : errorText}
                        </div>
                    </div>
                </form>
                <Button type="submit" btnClass="btn-primary mt-4 align-self-center w-75" id="next" onClick={() => clickAdd()}>{t("devices.add")}</Button>
                <div className="dot_layout mt-3 align-self-center">
                    <img alt="Step" className="dot me-1" src={icons.dot_unselected} />
                    <img alt="Step" className="dot ms-1" src={icons.dot_selected} />
                </div>
            </>
        )
     
    }
    
    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="add_device_dialog d-flex flex-row">
                <div className={`${MOBILE ? "d-none" : ""} w-50 bg-light-navy d-flex`}>
                    {page === 1
                        ? <img src={images.add_device_1} className="add_device_logo px-5 align-self-center" alt="..."/>
                        : <img src={images.add_device_2} className="add_device_logo px-5 align-self-center" alt="..."/>
                    }
                </div>
                <div className={`${MOBILE ? "w-100" : "w-50"} bg-white`}>
                        <div className="d-flex flex-row justify-content-center p-3 w-100 position-relative">
                            <h4 className="primary">{t("devices.add_device")}</h4>
                            <img className="cursor-pointer position-absolute top-0 end-0 pe-3 pt-3" src={icons.close_gray} onClick={() => handleClose()} alt="..." /> 
                        </div>
                        <div className="d-flex flex-column p-3">
                            {page === 1
                                ? renderFirstPage()
                                : renderSecondPage()
                            }
                        </div>
       
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default AddDevice;