import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../../helpers/customHooks';
import { useTranslation } from "react-i18next";
import { INDEX_URL, CUST, icons, STRIPE_TEST_KEY } from "../../helpers/constants";
import MonthYearSegment from '../PeriodSegment/PeriodSegement';
import Button from '../Buttons/Button';
import DeviceActivationList from '../Devices/DeviceActivationList';
import { Collapse } from '@mui/material';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCard from './Stripe/AddCard';
import CardRow from './Stripe/CardRow';
import * as request from "../../services/FetchService";

const DeactivatingInterim = (props) => {
    const [deactivatableDevices, setDeactivatableDevices] = useState(props.devices)
    const pricing = require(`./Stripe/Pricing/${process.env.REACT_APP_ASSETS}/pricelist.json`);
    const { t } = useTranslation();
	const MOBILE = useIsMobile();
    const [allSelected, setAllSelected] = useState(false)
	const [dots, setDots] = useState('')
	const [thinking, setThinking] = useState(false)
	const [step, setStep] = useState(1)

	const selectDevice = (index) => {
		// ONLY ONE
		let updatedDevices = [...deactivatableDevices];
		if (index !== -1) {
			// Determine if the current device is already selected
			const isAlreadySelected = updatedDevices[index].selected;
	
			// Set all devices' selected property to false initially
			updatedDevices.forEach(device => {
				device.selected = false;
			});
	
			// Toggle the selected state of the chosen device
			// If it was already selected, it becomes false, else true
			updatedDevices[index].selected = !isAlreadySelected;
		}
	
		// Update the state with the new list of devices
		setDeactivatableDevices(updatedDevices);
    }

	const nextStep = (fail) => {
		if(fail){
			setStep(4)
		}else{
			setStep(step + 1)
		}
		// setSelectedPlan(plan)
	}

	const getCurrentTotal = () => {
		let total = { monthly: 0, yearly: 0 }; // Initialize total object with monthly and yearly properties set to 0
	
		deactivatableDevices.forEach(obj => {
			const { canceled, period, plan } = obj;
			const { currency } = pricing["SE"];
			total.currency = pricing["SE"].currency
			if (!canceled) { // Check if canceled is false
				if (pricing["SE"].hasOwnProperty(plan)) {
					const price = pricing["SE"][plan][period === 'year' ? 'yearly' : 'monthly'];
					if (period === 'year') {
						total.yearly += price;
					} else {
						total.monthly += price;
					}
				} else {
					console.log(`Invalid plan: ${plan}`);
				}
			}
		});
	
		return total;
	}

	const getNewTotal = () => {
		let total = { monthly: 0, yearly: 0 }; // Initialize total object with monthly and yearly properties set to 0
	
		// Calculate total price without considering selected devices
		deactivatableDevices.forEach(obj => {
			const { canceled, period, plan, cc} = obj;
			total.currency = pricing["SE"].currency

			if (!canceled) { // Check if canceled is false
				if (pricing["SE"].hasOwnProperty(plan)) {
					const price = pricing["SE"][plan][period === 'year' ? 'yearly' : 'monthly'];
					if (period === 'year') {
						total.yearly += price;
					} else {
						total.monthly += price;
					}
				} else {
					console.log(`Invalid plan: ${plan}`);
				}
			}
		});
	
		// Subtract price of selected devices
		deactivatableDevices.forEach(obj => {
			const { canceled, period, plan, selected } = obj;
	
			if (selected && !canceled) { // Check if selected is true and canceled is false
				if (pricing["SE"].hasOwnProperty(plan)) {
					const price = pricing["SE"][plan][period === 'year' ? 'yearly' : 'monthly'];
					if (period === 'year') {
						total.yearly -= price;
					} else {
						total.monthly -= price;
					}
				} else {
					console.log(`Invalid plan: ${plan}`);
				}
			}
		});
	
		return total;
	}
	useEffect(() => {

		if(step === 2){
			setThinking(true)		
			deactivatePlan()
		}
	}, [step])

	const resetData = () => {

		let updatedDevices = [...deactivatableDevices];
		updatedDevices.forEach((device, index) => {
			device.selected = false;
		});
		setDeactivatableDevices(updatedDevices);
	
        props.finalize()
	}

	const getCheckedImeis = () => {
		let checkedDevices = deactivatableDevices.filter(device => device.selected).map(device => device.deviceid);
		return checkedDevices
	}

	const deactivatePlan = () => {
		let data = {
			deviceid: [getCheckedImeis()[0]],
		}
		request.post("", "dataplan/stop", data).then((response) => {
			if(!response.data.error){
				setThinking(false)
				nextStep()
			}else{
				setThinking(false)
				nextStep(true)
			}
		})
	}

    // useEffect(() => {
	// 	if(allSelected){
	// 		let updatedDevices = [...deactivatableDevices];
    //         updatedDevices.map((device, index) => {
	// 			device.selected = true
    //         })
	// 		setDeactivatableDevices(updatedDevices)
	// 	}else{
	// 		let updatedDevices = [...deactivatableDevices];
    //         updatedDevices.map((device, index) => {
	// 			device.selected = false
    //         })
	// 		setDeactivatableDevices(updatedDevices)
	// 	}
    // }, [allSelected])


    const renderStep1 = () => {

		const purchaseSummaryMobile = () => {
			return(
				<div className="position-absolute bottom-0 start-0 end-0 px-3 pb-3">
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
					<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${!deactivatableDevices.some(device => device.selected) ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
					</div>
				</div>
			)
		}

		const purchaseSummaryDesktop = () => {
			return(
				<div className="ms-4">
					<div className="d-flex flex-row align-items-center px-3 opacity-0 mb-2">
						<h4>""</h4>
						<div className="centered_line ms-2" />
					</div>
					<div className="card p-4 d-flex flex-column border-12 shadow-medium border-0">
						<Button onClick={() => nextStep()} btnClass={`btn-primary gradient-button-primary w-100 mt-auto ${!deactivatableDevices.some(device => device.selected) ? "disabled": ""}`}>{t("subscription.proceed")}</Button>
					</div>
				</div>
			)
		}
		if(MOBILE){
			return (
				<>
					<div className="d-flex flex-column my-3">
						<div className="d-flex flex-row align-items-center px-3 mb-2">
							<h4 className="text-nowrap">{t("subscription.select_device")}</h4>
							<div className="centered_line ms-3" />
						</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
                                <DeviceActivationList 
									text={t("devices.choose_all")}
									selected={deactivatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/>
								{deactivatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
					</div>
					{deactivatableDevices.some(device => device.selected) &&
						purchaseSummaryMobile()
					}
				</>
			)
		}else{
			return(
				<>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div className="d-flex flex-column w-75" style={{maxWidth: "500px"}}>
							<div className="d-flex flex-row align-items-center px-3 mb-2">
								<h4 className="text-nowrap">{t("subscription.select_device")}</h4>
								<div className="centered_line ms-3" />
							</div>
							<div className="border-12 subscription_device_list scrollable-y shadow-medium">
                                {/* <DeviceActivationList 
									text={t("devices.choose_all")}
									selected={deactivatableDevices.some(device => !device.selected) ? false : true}
									onClick={() => setAllSelected(!allSelected)}
								/> */}
								{deactivatableDevices.map((device, index) => (
									<DeviceActivationList
										key={index}
										text={device.name}
										subtitle={device.uniqueid}
										index={index}
										selected={device.selected ? true : false}
										onClick={() => selectDevice(index)}
									/>
								))}
							</div>
						</div>
	
						<div className="w-50" style={{maxWidth: "350px"}}>
							{purchaseSummaryDesktop()}
						</div>
					</div>
				</>
			)
		}	
	}

	useEffect(() => {
		let intervalId;
		if(thinking){
			let dotCount = 0
			intervalId = setInterval(() => {
				dotCount = (dotCount % 3) + 1; // Loop through 1, 2, 3 dots
				setDots('.'.repeat(dotCount))
			}, 500)
		}

		return () => {
			clearInterval(intervalId); // Clean up interval
		  };
	}, [thinking])

	const renderStep2 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 shadow-small border-12 d-flex">
					<h3 className="align-self-center mt-5">{t("subscription.processing")+dots}</h3>
				</div>
			</div>
		)
	}
	
	const renderStep3 = () => {
		return(
			<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
				<div className="card p-4 w-100 shadow-small border-12 d-flex flex-column align-items-center">
					<h3 className="mt-5">{t("subscription.deactivation_successful")}</h3>
					<p>{t("subscription.deactivation_successful_body")}</p>
					<hr className="w-100"/>
					<div className="d-flex flex-column align-items-center">
						<Button  onClick={() => resetData()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.done")}</Button>
					</div>
				</div>
			</div>
		)	
	}

	const renderError = () => (
		<div className={`mt-4 d-flex flex-column ${MOBILE ? "w-100" : "w-50"} align-self-center justify-content-center`}>
			<div className="card p-4 shadow-small border-12 d-flex">
				<h3 className="align-self-center mt-5">{t("subscription.something_went_wrong")}</h3>
				<Button  onClick={() => resetData()} btnClass="btn-primary gradient-button-primary mt-4">{t("subscription.go_back")}</Button>
			</div>
		</div>
	)

    return(
        <>
            <Button className="align-self-start mt-3" btnClass="btn-left-section" icon={icons.back} notext={true} onClick={() => props.finalize()}/>
			{step === 1
				? renderStep1()
				: step === 2
				? renderStep2()
				: step === 3
				? renderStep3()
				: step === 4
				? renderError()
				: null
			}
        </>
    )
}

export default DeactivatingInterim;